// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-heart-healthy-habits-js": () => import("./../../../src/pages/heart-healthy-habits.js" /* webpackChunkName: "component---src-pages-heart-healthy-habits-js" */),
  "component---src-pages-heart-truths-js": () => import("./../../../src/pages/heart-truths.js" /* webpackChunkName: "component---src-pages-heart-truths-js" */),
  "component---src-pages-heart-videos-js": () => import("./../../../src/pages/heart-videos.js" /* webpackChunkName: "component---src-pages-heart-videos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legacy-sign-up-js": () => import("./../../../src/pages/legacy-sign-up.js" /* webpackChunkName: "component---src-pages-legacy-sign-up-js" */),
  "component---src-pages-sign-up-thankyou-js": () => import("./../../../src/pages/sign-up-thankyou.js" /* webpackChunkName: "component---src-pages-sign-up-thankyou-js" */),
  "component---src-pages-supplements-js": () => import("./../../../src/pages/supplements.js" /* webpackChunkName: "component---src-pages-supplements-js" */),
  "component---src-pages-ttyh-podcast-js": () => import("./../../../src/pages/ttyh-podcast.js" /* webpackChunkName: "component---src-pages-ttyh-podcast-js" */)
}

